import React, { Component } from 'react'
import { Link, Router } from '@reach/router'
import { withAuth0 } from '@auth0/auth0-react'

import { Layout, Menu, Row, Col, Spin, Tooltip, Button } from 'antd'
import { AimOutlined, UserOutlined, SettingOutlined, ApartmentOutlined, ApiOutlined, FilePdfOutlined, WifiOutlined } from '@ant-design/icons'

import './App.css'
import ErrorMessages, { openNotification } from './utils/ErrorMessages'
import axios from 'axios'
import SubMenu from 'antd/lib/menu/SubMenu'

const Measurements = React.lazy(() => import(/* webpackChunkName: "Measurements" */ './Measurements'))
const Reports = React.lazy(() => import(/* webpackChunkName: "Reports" */ './Reports'))
const RemoteControl = React.lazy(() => import(/* webpackChunkName: "RemoteControl" */ './RemoteControl'))

const WebHooks = React.lazy(() => import(/* webpackChunkName: "WebHooks" */ './WebHooks'))
const Workflows = React.lazy(() => import(/* webpackChunkName: "Workflows" */ './Workflows'))
const OrganisationSettings = React.lazy(() => import(/* webpackChunkName: "OrganisationSettings" */ './OrganisationSettings'))
const Measurement = React.lazy(() => import(/* webpackChunkName: "Measurement" */ './Measurement'))
const ThreeD = React.lazy(() => import(/* webpackChunkName: "ThreeD" */ './ThreeD'))

const { Header, Content, Footer } = Layout

class App extends Component {
  state = {
    selectedTabs: ['/'],
    version: ''
  }

  setSelectedTab = (tab) => {
    this.setState({ selectedTabs: [tab] })
  }

  getData () {
    axios
      .get('/api/version')
      .then((response) => {
        this.setState({ version: response.data.version })
      }).catch((error) => {
        openNotification(error.response.status, error.response.data.msg)
      })
  }

  componentDidMount () {
    this.getData()
  }

  render () {
    const { isAuthenticated, isLoading, loginWithRedirect, logout } = this.props.auth0

    const { selectedTabs, version } = this.state

    if (isLoading) {
      return <div>Loading ...</div>
    }

    if (!isAuthenticated) {
      return loginWithRedirect()
    }

    const loading = false

    return (
      <React.Suspense fallback={null}>
        <Layout style={{ backgroundColor: 'white' }}>
          <Header className='header' style={{ paddingLeft: '20px', lineHeight: '40px', height: '40px' }}>
            <Row>
              <Col xs={1} sm={3}>
                <h1>
                  <i style={{ fontWeight: '300', color: 'white' }}>
                    Olypsys Vault
                  </i>
                </h1>
              </Col>

              <Col flex='auto'>
                <Menu
                  theme='dark'
                  mode='horizontal'
                  multiple={false}
                  selectedKeys={selectedTabs}
                  onSelect={(keyPath) => {
                    this.setState({ selectedTabs: [keyPath.key] })
                  }}
                  style={{ lineHeight: '40px', height: '40px' }}
                >
                  <Menu.Item key='/'>
                    <Link to='/'>
                      <span>
                        <AimOutlined />
                        <span>Measurements</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='/reports'>
                    <Link to='/reports'>
                      <span>
                        <FilePdfOutlined />
                        <span>Reports</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='/remote-control'>
                    <Link to='/remote-control'>
                      <span>
                        <WifiOutlined />
                        <span>Remote Control</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='/workflows'>
                    <Link to='/workflows'>
                      <span>
                        <ApartmentOutlined />
                        <span>Workflows</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='/hooks'>
                    <Link to='/hooks'>
                      <span>
                        <ApiOutlined />
                        <span>Web Hooks</span>
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='/organisation-settings'>
                    <Link to='/organisation-settings'>
                      <span>
                        <SettingOutlined />
                        <span>Organisation Settings</span>
                      </span>
                    </Link>
                  </Menu.Item>
                </Menu>
              </Col>
              <Col flex='0 0 130px'>
                <Menu
                  multiple={false}
                  selectedKeys={selectedTabs}
                  onSelect={(keyPath) => {
                    this.setState({ selectedTabs: [keyPath.key] })
                  }}
                  theme='dark'
                  mode='horizontal'
                  style={{ lineHeight: '40px', height: '40px' }}
                  justify='end'
                >
                  <SubMenu
                    key='UserOptions'
                    title={
                      <Tooltip placement='left' title={this.state.username}>
                        <UserOutlined
                          style={{ color: '#ffffff', fontSize: '19px', verticalAlign: '-0.2em' }}
                        />
                      </Tooltip>
}
                  >
                    <Menu.ItemGroup title='User Options'>
                      <Menu.Divider />
                      <Menu.Item key='12'>
                        <Button
                          type='link'
                          onClick={() => logout({ returnTo: window.location.origin })}
                          style={{ padding: '0px' }}
                        >
                          Log Out
                        </Button>
                      </Menu.Item>
                    </Menu.ItemGroup>
                  </SubMenu>
                </Menu>
              </Col>

            </Row>
          </Header>
          <Content style={{ margin: '39px 32px' }}>
            {loading && <Spin className='loading' />}
            <div className='content'>
              {loading === false && (
                <Router>
                  <Measurements exact path='/' />
                  <Reports exact path='/reports' />
                  <RemoteControl exact path='/remote-control' />
                  <WebHooks exact path='/hooks' />
                  <OrganisationSettings exact path='/organisation-settings' />
                  <Workflows exact path='/workflows' />
                  <Measurement path='measurements/:requestId' />
                  <ThreeD path='/measurements/3d/:requestId' />
                  <ErrorMessages
                    default
                    error={{ data: { msg: ' Page not found' }, status: 404 }}
                  />
                </Router>
              )}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center', backgroundColor: '#fafafa', position: 'sticky', bottom: '0em' }}>
            <a href='https://olypsys.com/'>Olypsys Technologies Ltd.</a> ©{' '}
            <i>
              {new Date().getFullYear()} - Olypsys Vault {version}
            </i>
          </Footer>
        </Layout>
      </React.Suspense>

    )
  }
}

export default withAuth0(App)
